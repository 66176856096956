import { HrCreateVacationApplicationRequest } from "@/types/hr/vacationApplication/hrCreateVacationApplicationRequest";
import { formatDate } from "@/utils/dates";
import { isoDateFormat } from "@/utils/formats";

export interface ApiHrCreateUnplannedVacationApplicationRequest {
	vacationSubstituteEmployeeId: string;
	startDate: string;
	endDate: string;
}

export class ApiHrCreateUnplannedVacationApplicationRequestService {
	static map(source: HrCreateVacationApplicationRequest): ApiHrCreateUnplannedVacationApplicationRequest {
		return {
			vacationSubstituteEmployeeId: source.vacationSubstituteEmployeeId,
			startDate: formatDate(source.startDate, isoDateFormat) as string,
			endDate: formatDate(source.endDate, isoDateFormat) as string
		};
	}
}
